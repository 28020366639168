.graphic-container {
  height: 100%;
  margin-top: 2em;
  margin-left: 2em;
  width: 512px;
  padding-left: 3em;

  @media screen and (max-width: $mobile) {
    display: none;
  }

  .cloud-1 {
    display: inline;
    margin: auto;
    margin-top: -70px;
    position: absolute;
    z-index: 2;
    animation: hfloat 3s infinite;
  }
  .cloud-2 {
    display: inline;
    margin: auto;
    margin-top: -60px;
    position: absolute;
    
    z-index: 1;
    animation: hfloat2 3s infinite;
  }
  .keyboard {
    display: inline;
    margin: auto;
    position: absolute;

    width: 400px;
    z-index: 3;
    animation: float 4s infinite;
    top: -10px;
  }

  .graphic-header {
    display: flex;
    margin-left: 2em;
    margin-bottom: 2em;
    justify-content: center;
    background-color: $light;
    border: 1px solid white;
    .logo {
      color: white;
      font: normal 1.5em $logo-display;
      line-height: 60px;
      padding-right: .5em;
    }
    .header-text {
      color: white;
      font: bold 20px $main-sans;
      line-height: 58px;
    }
  }
}

@keyframes float {
  from {
    margin-top: 1em;
  }

  50% {
    margin-top: 2em;
  }

  to {
    margin-top: 1em;
  }
}

@keyframes hfloat {
  from {
    margin-left: 0;
  }

  50% {
    margin-left: 10px;
  }

  to {
    margin-left: 0;
  }
}
@keyframes hfloat2 {
  from {
    margin-left: 100px;
  }

  25% {
    margin-left: 90px;
  }

  to {
    margin-left: 100px;
  }
}

.landing-graphic-old {
  width: calc(100% - 2em);
  padding: 2em;
  margin-left: 2em;
  display: flex;
  background-color: $medium;
  border: 1px solid white;

  .line {
    opacity: .9;
    height: 18px;
    margin-top: 1em;
    border-radius: 5px;
  }

  .line:first-child {
    margin-top: 0;
  }

  .graphic-col {
    width: 50%;
    .line-1 {
      background-color: #7DE2D1;
      width: 50%;
    }
    .text-line {
      text-shadow:2px 2px rgba(0,0,0,.3);
      margin-top: 1em;
      font: bold 18px $main-sans;
      color: white;
    }
    .line-3 {
      width: 80%;
      background-color: #339989;
    }
    .line-4 {
      width: 55%;
      background-color: #6457A6;
    }
    .line-5 {
      width: 65%;
      background-color: #5C2751;
    }
    .line-6 {
      width: 90%;
      background-color: #702126;
    }
    .line-7 {
      width: 70%;
      background-color: #E58142;
    }
    .line-8 {
      width: 90%;
      background-color: #217050;
    }
  }

  .graphic-col-2 {
    display: flex;
    flex-direction: column;
    align-content: center;

    .line {
      align-self: flex-end;
    }

    .text-line {
      text-align: right;
    } 
  }
}