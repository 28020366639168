.general-form {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-content: flex-start;

  .general-input {
    padding: 1.25em;
    padding-left: 1em;
    font: 600 16px $main-sans;
    border: 1.5px solid $dark;
    border-radius: 3px;
    margin-top: .5em;
    margin-right: 1.5em;
    margin-left: 1.5em;
    height: 2.5em;
  }

  .submit-button {
    width: 150px;
    height: 40px;
    margin: auto;
    margin-top: 1em;
    text-align: center;
    background-color: $dark;
    font: 600 16px $main-sans;
    color: white;
    padding-top: .5em;
    padding-bottom: .5em;
    border-radius: 2px;
  }

  .error-container {
    background-color: #ed4337;
    margin-top: 1em;
    font: 400 16px $main-sans;
    color: white;
    padding: .5em;
    margin-right:1.5em;
    margin-left:1.5em;
  }

  .success-container {
    background-color: #4bb543;
    margin-top: 1em;
    font: 400 16px $main-sans;
    color: white;
    padding: .5em;
    margin-right:1.5em;
    margin-left:1.5em;
  }
}

