.NavigateMenuBar {
  display: flex;
  margin: auto;
  margin-top: 1em;
  justify-content: center;
  max-width: 550px;

  .navigate-button {
    border-radius: 999em;
    padding: 1em;
    height: 3.5em;
    width: 3.5em;
    text-align: center;
    margin-right: 1em;
    span {
      vertical-align:middle;
    }
  }

  .navigate-button:last-child {
    margin-right: 0;
  }


  .navigate-home {
    border: 2px solid white;
    color: white;
    
    span {
      margin-top: -2px;
      margin-left: -1px;
    }
  }
  .navigate-restart {
    background-color: white;

  }
}