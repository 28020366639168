.history-card {
  background-color: white;
  margin-bottom: .5em;
  border-radius: 6px;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  color: black;
  min-width: 370px;
  overflow: hidden;
  .history-card-title {
    margin-bottom: 4px;
    font: 600 16px $main-sans;
    text-transform: capitalize;
  }
  .history-card-left {
    padding-right: 10px;
    border-right: 2px solid #DFDFDF;
    overflow: clip;
    flex-shrink: 0;
  }
  .tag {
    background-color: #DFDFDF;
    color: black;
    padding-left: .5em;
    padding-right: .5em;
    margin-right: .25em;
    min-width: 80px;
    font: normal 13px $code-mono;
  }

  .time-tag {
    display: inline-block;
    min-width: 76px;
    height: 100%;
    text-align: center;

  }

  .history-card-wpm {
    margin-left: 10px;
    padding-left: .5em;
    padding-right: .5em;
    border: 2px solid black;
    border-radius: 5px;
    align-self: center;
    flex-basis: 40%;
    flex-grow: 2;
    flex-shrink: 4;
    min-width: 115px;
    .wpm-display {
      font: bold 2em $main-sans;
      height: 100%;
      text-align: center;
      line-height: 1.25em;
    }
    .wpm-label {
      font: bold 18px $main-sans;
    }
  }

  .history-card-match-length {
    height: 100%;
    margin-left: 10px;
    flex-grow: .25;
    .match-length-label {
      font: 600 20px $main-sans;
      text-align: center;
    }
    .match-length-display {
      display: flex;
      flex-direction: column;
      img {
        height: 32px;
        margin-bottom: -.5em;
      }
    }
  }
}

.welcome-history-card {
  background-color: $medium;
  margin-left: 1em;
  margin-right: 1em;
  align-self: flex-start;
  flex-shrink: 1;
  @media (max-width: $mobile) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1em;
    flex-basis: 100%;
  }
}

a:last-of-type > .history-card {
  margin-bottom: 0;
}