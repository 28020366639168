.header-container {
  height: 60px;
  width: 100%;
  background-color: $dark;
  display: flex;
  justify-content: space-between;

  .promo {
    font: normal 16px $main-sans;
    line-height: 60px;
    padding-right: 1em;
    color: white;
    flex-shrink: 0;
    
    a {
      text-decoration: underline;
    }
  }

  .promo-dark {
    font: normal 16px $main-sans;
    line-height: 60px;
    padding-right: 1em;
    color: white;
    flex-shrink: 0;
    opacity: .3;
    a {
      text-decoration: underline;
    }
  }


  .logo {
    opacity: .3;
    color: white;
    font: normal 1.5em $logo-display;
    line-height: 60px;
    padding-left: 9px;
    transition: ease .25s;
    margin-top: -10px;
    a {
      text-decoration: none;
    }
  }
  .logo:hover {
    cursor: pointer;
    opacity: 1;
  }
  .game-title-container {
    margin-top: 1em;
    color: white;
    width:  33.333%;

    .game-title {
      font: normal 1em $main-sans;
      margin: auto;
      text-align: center;
      width: 120px;
      background-color: $medium;
      padding-top: .5em;
      padding-bottom: .5em;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  
  .spacer {
    width: 33.333%;
  }
}

//Landing customizations
.landing-header {
  background-color: $medium;

  .logo {
    opacity: 1;
  }
}