.leaderboard-item {
    background-color: white;
    margin-bottom: .5em;
    border-radius: 6px;
    padding: 1em;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    align-items: center;
    color: black;
    .leaderboard-number {
        box-sizing: border-box;
        border: 2px solid black;
        border-radius: 999em;
        height: 25px;
        width: 25px;
        text-align: center;
        margin-right: .5em;
        font: 600 16px $main-sans;
        line-height: 20px;
    }
    .leaderboard-name {
        text-align: center;
        padding-right: .5em;
        font: normal 20px $main-sans;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 16px;
        border-right: 2px solid #DFDFDF;
    }
    .leaderboard-score {
        font: bold 25px $main-sans;
        text-align: center;
        padding-left: .5em;
        padding-left: 10px;
        display: flex;
        align-items: center;
        height: 25px;
        margin-bottom: 3px;
        .leaderboard-wpm {
            font-size: 18px;
            font-weight: 600;
            height: 18px;
            margin-bottom: 4px;
            margin-left: 5px;
        }
    }
} 
.leaderboard-item:last-child {
    margin-bottom: 0;
}