//COLORS 
$dark: #112d32;
$medium: #254E58;
$light: #40798C;

//Fonts 
$main-sans: 'Source Sans Pro';
$logo-display: 'Fugaz One';
$code-mono: 'Fira Code';

//Screen Size Maximums
$mobile: 780px;
$tablet: 1150px;
$laptop: 1525px;
